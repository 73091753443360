import "./Header.scss";
import image from './header.jpg';
import { Link } from '@mui/material';
import ghIcon from "./github-icon.png";
import { Card, CardContent, CardActions, Typography, Box } from '@mui/material';
import Button from '@mui/material/Button';

const portInfo = [
  {
    name: 'Solar System Simulation',
    tech: 'Unity, C#',
    view: 'Simmer.io',
    link: 'https://simmer.io/@korikenn/solar-system-simulator'
  },
  {
    name: 'Orbit Social Media Application',
    tech: 'Ruby on Rails',
    view: 'live site',
    link: 'https://afternoon-spire-34284.herokuapp.com/'
  },
  {
    name: 'KNN Digit Recognition',
    tech: 'Jupyter Notebook',
    view: 'GitHub',
    link: 'https://github.com/kkreine/knn-digit-recognition'
  }
]

const Port = () => {
  return (
    <>
      <Box gap={2} sx={{
      }} className='box'>
        {
          portInfo.map((item) => {
            return (
              <Card className='card'>
                <CardContent className='content'>
                  <Typography sx={{ fontSize: 14, fontFamily: 'Garamond, serif' }} color="#8e3170">
                    <b>{item.tech}</b>
                  </Typography>
                  <Typography sx={{ fontFamily: 'Garamond, serif' }} color="#5085a5">
                    <b>{item.name}</b>
                  </Typography>
                </CardContent>
                <CardActions className='actions'>
                  <Button size="small"
                    href={item.link}
                    target="_blank" >
                    <span className='green-text'><b>View on {item.view}</b></span>
                  </Button>
                </CardActions>
              </Card>
            )
          })
        }
      </Box>
    </>
  )
}

const Header = () => {
  const aboutInfo = "Hi y'all! I'm a software developer, who enjoys learning new technologies, being challenged, and working on a supportive, mutually respectful team. In my free time you can find me taking online courses, building pet projects, biking through the park, and gardening fruits, veggies, and succulents.";
  return (
    <div className='header-container' style={{ backgroundImage: `url(${image})` }}>
      <div className='name-header'>
        <h1 className='name-h1'>Kori Reine</h1>
        <h2 id='pronouns'>(she/her)</h2>
        <div className='about-info'>
          <h4>{aboutInfo}</h4>
        </div>
        <div className='flex-logo'>

          <Link href='https://github.com/kkreine'><img src={ghIcon} alt='GitHub icon' className='logo-img' /></Link>
        </div>
      </div>
      <Port />
    </div>
  );
};

export default Header;