import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import "./Experience.scss"

const Experience = () => {
  return (
    <div className='exp-container'>
      <div className='exp-title-container'>
        <h1 className='exp-h1'>EXPERIENCE</h1>
      </div>
      <div className='exp-item'>
        <h1>Junior Consultant</h1>
        <h2>Rural Sourcing, Inc</h2>
        <h2>May 2022 - August 2022</h2>
        <h3>As a junior associate at Rural Sourcing, I work as a front end developer on an Agile team to build an internal application with a ASP.NET backend.</h3>
        <h4>[TypeScript, React, Chakra UI]</h4>
      </div>
      <div className='other'>
        <h2><u>OTHER EXPERIENCE</u></h2>
        <h4>C# Game Programming Tutor</h4>
        <h4>Online Store and Social Media Assistant</h4>
        <h4>Recruiting Assistant</h4>
        <h4>Tutor: Pre-K to 9th grade</h4>
        <h4>Team Leader at Jumpstart</h4>
      </div>
    </div>
  );
};

export default Experience;