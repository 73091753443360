import "./Volunteering.scss"

const Volunteering = () => {
  return (
    <div className='vol-container'>
      <div className='title-container'>
        <h1 className="vol-title">CONTRIBUTING TO MY COMMUNITY</h1>
      </div>
      <div className='vol-items'>
        <div className='current'>
          <h3>Green Light New Orleans: Rain barrel prep</h3>
        </div>
        <div className='previous'>
          <h3>PREVIOUS</h3>
          <h4>Atheist Community of Austin - Video Streaming</h4>
          <h4>Boys Hope Girls Hope - Tutor</h4>
          <h4>Anna's Place - After School Program</h4>
        </div>
      </div>
    </div>
  );
};

export default Volunteering;