import './Contact.scss'
import { Link } from '@mui/material';
import ghIcon from "./github-icon.png";

const Contact = () => {
  return (
    <div className='contact-container'>
      <div className='contact-info'>
        <h1>contact</h1>
        <h3>kori.kreine@gmail.com</h3>
        <h3>kori-k@matrix</h3>
        <h3><Link href='https://github.com/kkreine'><img src={ghIcon} alt='GitHub icon' className='logo-img' /></Link> </h3>
      </div>
    </div>
  );
};

export default Contact;