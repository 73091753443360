import { Box } from "@mui/material";
import Coursework from "./Coursework/Coursework";
import "./Education.scss";

const data = [
  {
    name: 'Loyola University New Orleans',
    dates: 'May 2022',
    degreeName: 'Bachelor of Science, Cybersecurity',
    des1: 'Summa Cum Laude.',
    des2: 'Alpha Sigma Nu Honor Society.',
    des3: 'National Society of Leadership and Success.',
    des4: 'Omicron Delta Kappa Honor Society.'
  },
  {
    name: 'Tech Talent South',
    dates: 'September - November 2019',
    degreeName: 'Java Code Immersion Boot Camp',
    des1: 'Eight week intensive course taken while enrolled in college full-time.',
    des2: 'Topics covered: Java, SpringBoot, Domain Modeling, HTTP and "RESTful" Design, HTML/CSS, JavaScript and AJAX, and understanding APIs',
    des3: 'Course project: Java Springboot imitation application of Twitter.'
  }
]
const Education = () => {
  return (
    <div className='education-container'>
      <div className='title'>
        <h1 className='ed'><b>EDUCATION</b></h1>
      </div>
      <div classname='school-flex'>
        <div className='degree'>
          <h2 className='school-name'>{data[0].name}</h2>
          <hr />
          <div className='degree-name-dates'>
            <h3>{data[0].degreeName}</h3>
            <h3>{data[0].dates}</h3>
            <div className='descr-degree'>
              <ul>
                <li><h4>{data[0].des1}</h4></li>
                <li><h4>{data[0].des2}</h4></li>
                <li><h4>{data[0].des3}</h4></li>
                <li><h4>{data[0].des4}</h4></li>
              </ul>
            </div>
          </div>
        </div>
        <div className='title'>
          <h1 className='ed'>COURSEWORK</h1>
        </div>
        <div className='coursework'>
          <div className='coursework-components'>
            <Coursework />
          </div>
        </div>
        <div className='degree'>
          <h2 className='school-name'>{data[1].name}</h2>
          <hr />
          <div className='degree-name-dates'>
            <h3>{data[1].degreeName}</h3>
            <h3>{data[1].dates}</h3>
            <div className='descr-degree'>
              <ul>
                <li><h4>{data[1].des1}</h4></li>
                <li><h4>{data[1].des2}</h4></li>
                <li><h4>{data[1].des3}</h4></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>

  );
};

export default Education;