import About from './components/Pages/About/About';
import { Divider } from '@mui/material';
import Contact from './components/Pages/Contact/Contact';
import Box from '@mui/material/Box'
import { AppBar, Toolbar, Typography } from '@mui/material';
import Header from './components/Pages/Header/Header';
import Navigation from './components/Navigation/Navigation';
import './App.scss';
import Resume from './components/Pages/Resume/Resume';
import Footer from "./components/Footer/Footer";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Experience from './components/Pages/Experience/Experience';
import Education from './components/Pages/Education/Education';
import Portfolio from './components/Pages/Portfolio/Portfolio';
import Volunteering from './components/Pages/Volunteering/Volunteering';

function App() {
  return (
    <div className="App">
      <Navigation />
      <section id='about'>
        <Header />
      </section>
      <Divider sx={{ color: '#396343' }} />
      <div classname='resume'>
        {/* <section id='portfolio'>
          <Portfolio />
        </section> */}
        <section id='education'>
          <Education />
        </section>
        <section id='experience'>
          <Experience />
        </section>
        <Volunteering />
      </div>
      {/* <Resume id='resume' /> */}
      <Divider sx={{ color: '#396343' }} />
      <section id='contact'>
        <Contact />
      </section>
      <Footer />
    </div>
  );
}

export default App;
