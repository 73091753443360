import "./Footer.scss";

const Footer = () => {
  return (
    <div className='footer'>
      <p></p>
    </div>
  );
};

export default Footer;