import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Course from "./Course/Course";
import "./Coursework.scss";

const Coursework = () => {
  const courses = [
    {
      name: 'Open Source Development',
      description: 'In this Independent Study, I studied open source processes, tools, licenses, and communities. Throughout this course, I read relevant texts, including The Cathedral and the Bazaar by Eric S. Raymond, Working in Public by Nadia Eghbal, and contributed to open source projects to get hands-on experience.'
    },
    {
      name: 'Software Engineering',
      description: 'In this software engineering course, I worked in team lead, backend, and frontend positions on an Agile software team to build a Java-based voting machine application. We covered the different software development lifecycle models, with a focus on Agile and Scrum.'
    },
    {
      name: 'Computing Ethics',
      description: 'Computing Ethics explored ethical theory with case study analysis. We discussed ethics questions and theories that can help one to make ethically sound decisions in their professional, personal, and social experiences with computing. The focus was the topics of networked communications, intellectual property, information privacy, computer and network security, computer and software reliability, professional ethics, workplace fairness, health, and the impacts of job automation.'
    },
    {
      name: 'Machine Learning',
      description: 'In this course, I learned machine learning algorithms such as, KNN, logistic regression, linear regression, decision tree, random forest, and neural network, through applying the algorithms to datasets in Jupyter notebook'
    },
    {
      name: 'Digital Forensics',
      description: 'This course explored the theory and practice of collecting, analyzing, and interpreting digital evidence. We used Kali Linux as our primary tool for analyzing evidence, and emphasized importance of controls and verification of digital evidence and the critical necessity of preserving and documenting a chain of custody for that evidence.'
    },
    {
      name: 'Internet Technologies',
      description: 'Internet technologies is a course that covers concepts related to distributed systems development, concurrent and asynchronous processing, process communications and network transport protocols, distributed application architecture and design, deployment, monitoring and debugging of internet-based systems, and web of things development. For a final course project, I built a React application and API to render gardening data in an application for home gardeners.'
    }
  ]
  return (
    <div className='coursework-container'>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        flexBasis: '100%',
        flexGrow: '100%',
        margin: 'none'
      }}
        gap={1}
        className='box'
      >
        {courses.map((course) => {
          return (
            <Course name={course.name} description={course.description} />
          );
        })}
      </Box>
    </div>
  );
};

export default Coursework;